<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :btnFlag="btnFlag"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
      ref="appList"
    >
      <div class="search" slot="subSlot">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAdd"
          size="small"
          >新增</el-button
        >
        <el-dropdown class="m-l-10">
          <el-button type="primary" size="small">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="setCouponPutAway(0)"
              >批量上架</el-dropdown-item
            >
            <el-dropdown-item @click.native="setCouponPutAway(1)"
              >批量下架</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </app-list>
    <el-dialog
      :title="handleType == 'create' ? '新增' : '编辑'"
      :visible.sync="dialogShow"
      :close-on-click-modal="false"
      width="1000px"
      @close="dialogShow = false"
    >
      <el-form
        label-position="right"
        :rules="rules"
        ref="form"
        label-width="80px"
        :model="form"
      >
        <el-row :gutter="20">
          <el-col :span="8"
            ><el-form-item label="面值" prop="price"
              ><el-input v-model.number="form.price" size="small">
                <template slot="append">元</template></el-input
              ></el-form-item
            ></el-col
          >
          <el-col :span="8"
            ><el-form-item label="使用门槛" prop="fullReduction"
              ><el-input v-model.number="form.fullReduction" size="small">
                <template slot="append">元</template></el-input
              ></el-form-item
            ></el-col
          >
          <el-col :span="8"
            ><el-form-item label="库存" prop="inventory"
              ><el-input
                v-model.number="form.inventory"
                size="small"
              ></el-input></el-form-item
          ></el-col>

          <el-col :span="8"
            ><el-form-item label="描述" prop="describe"
              ><el-input
                v-model="form.describe"
                size="small"
              ></el-input></el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="有效期" prop="validStartTime">
              <el-date-picker
                v-model="form.validTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                class="fullWidth"
                value-format="yyyy-MM-dd"
                @change="getValidTime"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="领取期限" prop="receiveStartTime">
              <el-date-picker
                v-model="form.receiveTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                class="fullWidth"
                value-format="yyyy-MM-dd"
                @change="getReceiveTime"
              >
              </el-date-picker> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {
    let self = this;
    return {
      rules: {
        price: [
          { required: true, message: "请填写面值", trigger: "blur" },

          { type: "number", message: "金额必须为数字值" },
        ],
        fullReduction: [
          { required: true, message: "请填写使用门槛", trigger: "blur" },
          { type: "number", message: "金额必须为数字值" },
        ],
        inventory: [
          { required: true, message: "请填写库存", trigger: "blur" },
          { type: "number", message: "库存必须为数字值" },
        ],
        validStartTime: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        receiveStartTime: [
          { required: true, message: "请选择领取期限", trigger: "change" },
        ],
      },
      dialogShow: false,
      remote: api,
      apiName: "/coupon",
      handleType: "",
      searchVal: "",
      form: {},
      btnFlag: { addShow: false, delShow: false },
      searchQuery: {
        siteName: "",
        siteCode: "",
        refreshNum: 0,
        backstage: true,
      },

      props: [
        {
          label: "面值（元）",
          prop: "price",
          align: "center",
          width: "120px",
        },

        {
          label: "使用门槛（元）",
          prop: "fullReduction",
          align: "center",
          width: "120px",
        },

        {
          label: "库存",
          prop: "inventory",
          align: "center",
          width: "120px",
        },
        {
          label: "描述",
          prop: "describe",
          align: "center",
        },

        {
          label: "有效期",
          prop: "validStartTime",
          align: "center",
          type: "html",
          width: "220px",
          formatter: function (row) {
            return (
              "<span>" +
              row.validStartTime +
              "至" +
              row.validEndTime +
              " </span>"
            );
          },
        },
        {
          label: "领取时间",
          prop: "receiveStartTime",
          align: "center",
          type: "html",
          width: "220px",
          formatter: function (row) {
            return (
              "<span>" +
              row.receiveStartTime +
              "至" +
              row.receiveEndTime +
              " </span>"
            );
          },
        },

        {
          label: "是否上架",
          prop: "isPutaway",
          align: "center",
          type: "html",
          formatter: function (row) {
            const map = [
              { color: "#409EFF", text: "发行中" },
              { color: "#999", text: "仓库中" },
            ];
            return (
              "<span style='color:" +
              map[row.isPutaway].color +
              "'>" +
              map[row.isPutaway].text +
              "</span>"
            );
          },
        },

        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handler: function () {
                  self.showEdit(row);
                },
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    async setCouponPutAway(value) {
      if (!this.$refs.appList.selecTionArr.length) {
        this.$message.error("请至少选择一条数据！");
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });

      try {
        let ids = [];
        this.$refs.appList.selecTionArr.forEach((element) => {
          ids.push(element.id);
        });

        let res = await api.commonPost({
          apiName: "/coupon/isPutaway",
          ids: ids,
          isPutaway: value,
        });
        this.$message.success(res.message);
        this.searchQuery.refreshNum++;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    showAdd() {
      this.form = {};
      this.handleType = "create";
      this.dialogShow = true;
    },

    showEdit(row) {
      this.form = row;

      this.form.validTime = [this.form.validStartTime, this.form.validEndTime];
      this.form.receiveTime = [
        this.form.receiveStartTime,
        this.form.receiveEndTime,
      ];

      this.handleType = "update";
      this.dialogShow = true;
    },

    handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!(this.form.price < this.form.fullReduction)) {
            this.$message.error("优惠券面额不允许超过门槛金额！");
            return;
          }

          let loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)",
          });
          try {
            let obj = Object.assign({}, this.form, {
              apiName: "/coupon/" + this.handleType,
            });
            let res = await api.commonPost(obj);
            this.$message.success(res.message);
            this.searchQuery.refreshNum++;
            this.dialogShow = false;
          } catch (error) {
            console.log(error);
          } finally {
            loading.close();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getReceiveTime(e) {
      console.log(e);

      this.form.receiveStartTime = e[0];
      this.form.receiveEndTime = e[1];
    },

    getValidTime(e) {
      this.form.validStartTime = e[0];
      this.form.validEndTime = e[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.fullWidth {
  width: 100% !important;
}
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
